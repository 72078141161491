@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
html,
body {
	padding: 0;
	margin: 0;
	font-family: "Nunito Sans", sans-serif !important;
}

.dragging-helper-class-dark div {
  color: white;
}

.dragging-helper-class-light div {
  color: black;
}

table.ukc thead th {
	position: sticky;
	top: 0;
	z-index: 1;
}

table.ukc tbody th {
	position: relative;
}
table.ukc thead th:first-child {
	position: sticky;
	left: 0;
	z-index: 2;
}
table.ukc tbody th {
	position: sticky;
	left: 0;
	z-index: 1;
}

.grab {
	cursor: -webkit-grab;
	cursor: grab;
}

.grabbing {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

html {
	height: -webkit-fill-available;
}
a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

:root {
	--avatar-size: 40px;
}

.circleInitials {
	background-color: #cce9e7;
	border-radius: 50%;
	height: var(--avatar-size);
	text-align: center;
	width: var(--avatar-size);
}

.initials {
	font-size: calc(var(--avatar-size) / 2);
	line-height: 1;
	color: #009384;
	position: relative;
	top: calc(var(--avatar-size) / 4);
}

@font-face {
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe0qMImSLYBIv1o4X1M8cce9I9tAcVwo.woff2)
		format("woff2");
}

@font-face {
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBs5tU1ECVZl_.woff2)
		format("woff2");
}
